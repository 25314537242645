// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/shared/FormElements/LoginTextField.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/shared/FormElements/LoginTextField.tsx");
  import.meta.hot.lastModified = "1730584816000";
}
// REMIX HMR END

import { Box, InputAdornment, TextField } from '@mui/material';
import { useReducer } from 'react';
import { softShadow } from '~/common/commonStyles';
const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: true
      };
    case 'TOUCH':
      return {
        ...state,
        isTouched: true
      };
    default:
      return state;
  }
};
function LoginTextField({
  name,
  label,
  type = "text",
  value,
  icon,
  error,
  placeHolder
}) {
  _s();
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: value,
    isValid: true,
    isTouched: false
  });
  const changeHandler = event => {
    dispatch({
      type: 'CHANGE',
      val: event.target.value
    });
  };
  const touchHandler = () => {
    dispatch({
      type: 'TOUCH'
    });
  };
  return <Box sx={{
    ...softShadow,
    px: 2,
    py: 1
  }}>
            <TextField key={name} name={name} label={label} type={type} value={inputState.value} onChange={changeHandler} onBlur={touchHandler} placeholder={placeHolder} variant="standard" fullWidth error={error !== ''} helperText={error} inputProps={{
      style: {
        padding: 0
      }
    }} InputProps={{
      disableUnderline: true,
      startAdornment: <InputAdornment position="start">
                            {icon}
                        </InputAdornment>
    }} />
        </Box>;
}
_s(LoginTextField, "DPMgRS4hSvUNfwO8BnFI281UjeQ=");
_c = LoginTextField;
export default LoginTextField;
var _c;
$RefreshReg$(_c, "LoginTextField");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;